import { useEffect, useState } from 'react';

import { Drop, DropBtn, DropItem, DropMenu } from '@playbooks/interface/drops';
import { Col } from '@playbooks/interface/grid';
import { Div, Span } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';
import { SearchTag } from 'components/search/search-tag';
import { useStore } from 'contexts';
import { useQuery } from 'hooks';
import { SearchDivForm, SearchForm } from 'molecules/forms';
import { DropList } from 'molecules/lists';
import { Pagination } from 'molecules/paginations';

const SearchSelectDrop = ({ id, value, options, placeholder = '', onChange, tailwind }) => {
	const [results, setResults] = useState([]);
	const [params, setParams] = useState<any>({ pageSize: 20, sortProp: 'name', sortValue: 'asc' });
	const [meta, setMeta] = useState({ page: 0, pageSize: 0, totalRecords: 0 });
	const [query, setQuery] = useState('');
	const [open, setOpen] = useState(false);
	const [ref, setRef] = useState(null);
	const { url, api, keyName, icon } = options;
	const store = useStore();

	// Computed
	const computedValue = value ? value[keyName] : value;

	// Hooks
	useEffect(() => {
		if (open) searchDb();
	}, [open, params, query]);

	// Actions
	const [searchDb, loading] = useQuery(async () => {
		const response = await store.query({ url, params: { ...params, ...api, query } });
		setResults(response.data);
		setMeta(response.meta);
	});

	// Methods
	const onOpen = () => setOpen(true);

	const onClick = option => {
		setOpen(false);
		setQuery('');
		onChange(option);
	};

	const onClose = () => setOpen(false);

	// Render
	return (
		<Drop ref={setRef} open={open} onClose={onClose} {...tailwind?.drop}>
			{value ? (
				<SearchDivForm prevIcon='magnifying-glass' tailwind={tailwind?.search}>
					<SearchTag result={value} options={options} onRemove={() => onClick(null)} />
				</SearchDivForm>
			) : (
				<SearchForm
					id={id}
					delay={100}
					prevIcon='magnifying-glass'
					query={query}
					setQuery={setQuery}
					placeholder={placeholder}
					onFocus={onOpen}
					onClear={() => setQuery('')}
					tailwind={tailwind?.search}
				/>
			)}
			<DropMenu ref={ref} open={open} display='flex flex-col' location='left-0' maxHeight='h-[250px]'>
				<DropList data={results} loading={loading} tailwind={{ grid: { spacing: 'py-2' } }}>
					{results.map((option, i) => (
						<Col key={i} spacing='px-2'>
							<DropItem>
								<DropBtn
									prevIcon={!option.thumbnail && icon}
									prevImg={option.thumbnail}
									onClick={() => onClick(option)}
									span={{ display: 'flex-between', width: 'w-full' }}>
									<Span>{option[keyName]}</Span>
									{computedValue === option[keyName] && <FarIcon icon='check' />}
								</DropBtn>
							</DropItem>
						</Col>
					))}
				</DropList>
				<Div border='border-t' spacing='p-2'>
					<Pagination type='slider' meta={meta} params={params} setParams={setParams} />
				</Div>
			</DropMenu>
		</Drop>
	);
};

export { SearchSelectDrop };
