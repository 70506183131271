import { useMemo, useState } from 'react';

import { Drop, DropBtn, DropItem, DropList, DropMenu } from '@playbooks/interface/drops';
import { Span } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';
import { SearchTag } from 'components/search/search-tag';
import { SearchDivForm, SearchForm } from 'molecules/forms';
import { capitalize } from 'utils';

const FilterSearchDrop = ({ id, keyName, prevIcon = 'filter', value, placeholder, options, onChange, tailwind }) => {
	const [data, setData] = useState([]);
	const [query, setQuery] = useState('');
	const [open, setOpen] = useState(false);
	const [ref, setRef] = useState(null);

	// Computed
	const computedValue = value ? value[keyName] : value;

	const computedOptions = useMemo(() => {
		return options?.filter(v => v[keyName]?.toLowerCase().includes(query?.toLowerCase()));
	}, [options, query]);

	const isActive = options?.includes(value);

	// Methods
	const onClose = () => setOpen(false);

	const onOpen = () => setOpen(true);

	const onClick = option => {
		setOpen(false);
		setQuery('');
		onChange(option);
	};

	// Render
	return (
		<Drop ref={setRef} open={open} onClose={onClose} {...tailwind?.drop}>
			{value ? (
				<SearchDivForm prevIcon={prevIcon} tailwind={tailwind.search}>
					<SearchTag result={value} onRemove={() => onClick(null)} />
				</SearchDivForm>
			) : (
				<SearchForm
					id={id}
					delay={100}
					prevIcon={prevIcon}
					query={query}
					setQuery={setQuery}
					placeholder={placeholder}
					onFocus={onOpen}
					onClear={() => onClick(null)}
					tailwind={tailwind?.search}
				/>
			)}
			<DropMenu ref={ref} open={open} location='left-0' maxHeight='max-h-[300px]'>
				<DropList>
					{computedOptions?.map((option, i) => (
						<DropItem key={i}>
							<DropBtn onClick={() => onClick(option)} span={{ display: 'flex-between', width: 'w-full' }}>
								<Span>{capitalize(option[keyName])}</Span>
								{computedValue === option[keyName] && <FarIcon icon='check' />}
							</DropBtn>
						</DropItem>
					))}
					{isActive && (
						<DropItem>
							<DropBtn nextIcon='xmark' onClick={() => onClick('')} span={{ display: 'flex-between', width: 'w-full' }}>
								Clear
							</DropBtn>
						</DropItem>
					)}
				</DropList>
			</DropMenu>
		</Drop>
	);
};

export { FilterSearchDrop };
