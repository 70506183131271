import { useEffect, useState } from 'react';

import { Drop, DropBtn, DropItem, DropList, DropMenu, DropToggle } from '@playbooks/interface/drops';
import { useStore } from 'contexts';
import { useSave } from 'hooks';
import { capitalize } from 'utils';

const SelectSaveDrop = ({ keyName = 'Status', value, options, rootApi, onNext }) => {
	const [open, setOpen] = useState(false);
	const [ref, setRef] = useState(null);
	const store = useStore();

	// Hooks
	useEffect(() => {
		return () => setOpen(false);
	}, []);

	// Actions
	const [onSave, saveTask] = useSave({}, keyName, async value => {
		const response = await store.updateRecord({ url: rootApi, data: { [keyName]: value } });
		onNext(response.data);
		onClose();
	});

	// Methods
	const onClose = () => setOpen(false);
	const onToggle = () => setOpen(!open);

	const onSelect = v => {
		setOpen(false);
		onSave(v);
	};

	// Render
	return (
		<Drop ref={setRef} open={open} onClose={onClose}>
			<DropToggle variant='border' onClick={onToggle}>
				{capitalize(value?.toString())}
			</DropToggle>

			<DropMenu ref={ref} open={open} className='right-0'>
				<DropList>
					{options.map((option, i) => (
						<DropItem key={i}>
							<DropBtn
								display='flex-between space-x-4'
								taskRunning={saveTask.running}
								nextIcon={value === option && 'check'}
								onClick={() => onSelect(option)}>
								{capitalize(option.toString())}
							</DropBtn>
						</DropItem>
					))}
				</DropList>
			</DropMenu>
		</Drop>
	);
};

export { SelectSaveDrop };
